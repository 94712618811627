<template>
	<div class="w-full block relative">
		<div class="max-w-5xl flex flex-col sm:flex-row mx-auto">
			<div class="w-350 bg-primary hidden sm:flex flex-col justify-center items-center px-5 triangle-content my-auto relative">
				<img src="../../../../assets/public/images/reg-sketch-icon.png"/>
				<Text size="3xl" weight="semibold" color="white" :content="btnText" class="uppercase text-center"/>
				<Text size="xl" weight="thin" color="white" :content="'IN under 3 minutes'" class="uppercase text-center"/>
			</div>
      <div class="bg-primary flex sm:hidden pt-6 rounded-t flex-col justify-center items-center px-5 my-auto relative">
        <Text size="3xl" weight="semibold" color="white" :content="btnText" class="uppercase text-center"/>
        <Text size="xl" weight="thin" color="white" :content="'IN under 3 minutes'" class="uppercase text-center"/>
        <img src="../../../../assets/public/images/reg-sketch-icon.png"/>
      </div>
			<div class="mx-auto bg-white shadow-lg rounded-b rounded-t-none sm:rounded-t px-10 py-12 w-full relative z-10">
				<div class="z-20">
					<Text size="xl" weight="semibold" color="gray-700" :content="title" class="text-center uppercase"/>
					<div class="text-sm text-gray-400 text-center mb-10 mt-4">
						Already have a profile? <a @click="$emit('goLogin')" class="text-primary underline font-semibold">Log in here</a>
					</div>
					<div class="grid grid-cols-1 sm:grid-cols-2 gap-5">
						<div v-for="form in forms" :key="form.label" class="w-full">
							<Select v-if="form.type === 'select'" v-model:selectedItem="form.value" :label="form.label"
											:items="form.options" :placeholder="form.placeholder"/>
							<Input v-else :type="form.type" v-model:value="form.value" :label="form.label"
										 :placeholder="form.placeholder"/>
						</div>
					</div>
					<div class="flex items-center my-7">
						<input type="checkbox" v-model="isChecked"
									 class="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 mr-2">
						<span class="text-sm text-gray-500">
							I agree to the <a @click="$emit('goTerms')" class="text-primary">Terms of Use</a> and <a @click="$emit('goPrivacy')" class="text-primary">Privacy Policy</a>
						</span>
					</div>
					<Button variant="primary" :content="btnText" :is-loading="isLoading"
									class="px-16 py-4 font-semibold text-md shadow-lg bg-primary text-white w-full mt-4"
									:onClick="action"/>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import Text from '../../../atoms/Text/Text.vue'
import VideoView from '../../../molecules/Content/VideoViewWithModal/VideoViewWithModal.vue'
import Input from '../../../molecules/Inputs/Component/Component.vue'
import Select from '../../../molecules/Selects/Component/Component.vue'
import Button from '../../../atoms/Button/Button.vue'

/**
 - Use it to show a contact for  m with overlapped images
 **/
export default {
	emits: [
		'onChangeAgree'
	],
	components: {
		Button,
		Text,
		VideoView,
		Input,
		Select
	},
	data() {
		return {
			isChecked: false,
		}
	},
	watch: {
		isChecked: function (val) {
			console.log(val, 'val');
			this.$emit('onChangeAgree', val)
		}
	},
	props: {
		title: {
			type: String,
			default: ''
		},
		description: {
			type: String,
			default: ''
		},
		forms: {
			type: Array,
			default: () => []
		},
		btnText: {
			type: String,
			default: ''
		},
		action: {
			type: Function,
			default: () => {
			}
		},
		confirmLabel: {
			type: String,
			default: ''
		},
		isLoading: {
			type: Boolean,
			default: false,
		}
	}
};
</script>

<style>
.triangle-content {
	height: 100%;
	width: 100%;
	border-radius: 20px 0 0 20px;
	background-color: #29baac;
	padding: 40px 40px 40px 20px
}

.triangle-content:before {
	top: -124px;
	left: 11px;
	border-bottom: 126px solid #29baac;
}

.triangle-content:after, .triangle-content:before {
	position: absolute;
	content: "";
	width: 0;
	height: 0;
	border-left: 340px solid transparent;
	border-right: 0 solid transparent;
	z-index: 1;
}

.triangle-content:after {
	bottom: -124px;
	left: 11px;
	border-top: 126px solid #29baac;
}
</style>
