<template>
  <div class="flex justify-center items-center relative mt-10 sm:mt-12">
    <button class="flex items-center absolute left-0" @click="backAction">
      <Icon size="6" name="ChevronLeftIcon" color="white"/>
      <Text size="md" color="white" content="Back"/>
    </button>
    <!-- ../../../../assets/public/images/logo.png -->
    <img src="https://faid-media.s3.us-west-1.amazonaws.com/logos/faid-logo-teal.png" class="h-8 cursor-pointer" @click="$emit('goHome')">
  </div>
</template>
<script>
import Text from '../../../atoms/Text/Text.vue'
import Icon from '../../../atoms/Icons/Icons.vue'

export default {
  components: {
    Text,
    Icon
  },
  props: {
    backAction: {
      type: Function,
      default: () => {}
    }
  },
};
</script>
