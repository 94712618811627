<template>
  <div class="h-screen banner flex justify-center px-2" :class="customClass">
    <div class="max-w-7xl w-full">
      <slot></slot>
    </div>
  </div>
</template>

<script>
/**
 * - Use it for full screen banner container
 **/

export default {
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
  components: {},
};
</script>

<style>
.banner {
  background-image: url(../../../../assets/public/images/login-background-5.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  background-color: #3b4956;
}
</style>