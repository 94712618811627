<template>
	<Template
			v-bind="{
				...params,
				isLoading
			}"
			@goBack="() => $router.go(-1)"
			@onSubmit="() => onRegister()"
			@onChangeAgree="val => isAgreed = val"
			@goTerms="() => $router.push('/terms')"
			@goPrivacy="() => $router.push('/privacy-policy')"
			@goLogin="() => $router.push('/login')"
      @goHome="() => $router.push('/')"
  />
</template>
<script>
import Template from '../components/templates/Register/Register';
import {c} from '@/components/constants.js'

export default {
	components: {
		Template,
	},
	methods: {
		async onRegister() {
			const forms = this.params.form.forms;
			console.log(forms, 'forms ====')
			if (!forms[0]?.value) {
				this.actions.alert.showError({message: 'Please insert first name!'});
				return false;
			}
			if (!forms[1]?.value) {
				this.actions.alert.showError({message: 'Please insert last name!'});
				return false;
			}
			if (!forms[2]?.value) {
				this.actions.alert.showError({message: 'Please insert email address!'});
				return false
			}
			if (!forms[3]?.value?.name) {
				this.actions.alert.showError({message: 'Please select the role!'});
				return false
			}
			if (!forms[4].value) {
				this.actions.alert.showError({message: 'Please insert password!'});
				return false
			}
			if (!this.isAgreed) {
				this.actions.alert.showError({message: 'Please agree to the terms and privacy'});
				return false;
			}

			try {
				this.isLoading = true;
				const user = await this.actions.createAppUser({email: forms[2]?.value, password: forms[4]?.value});
				console.log(user, 'user')
				if (user?.user?.id) {
					await this.actions.login({email: forms[2]?.value, password: forms[4]?.value, isRegister: true});
					if (this.state.isLoggedIn) {
						await this.actions.user.saveUser({
							where: {id: this.state.currentUser?.id},
							data: {
								firstName: forms[0]?.value,
								lastName: forms[1]?.value,
								groups: {
									create: [
										{
											name: forms[3]?.value?.name,
											type: 'ROLE'
										}
									]
								}
							}
						})
						this.actions.alert.showSuccess({message: 'Registered successfully!'});
						this.$router.push('/dashboard')
					}
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.isLoading = false;
			}
		}
	},
	data() {
		return {
			params: c.register,
			isLoading: false,
			isAgreed: false,
		}
	},
}
</script>
