<template>
	<Container :customClass="'overflow-auto'">
		<Header :back-action="() => $emit('goBack')" @goHome="() => $emit('goHome')"/>
		<RegisterForm class="mt-10 sm:mt-20" v-bind="form" :action="() => $emit('onSubmit')" @goLogin="() => $emit('goLogin')" @goTerms="() => $emit('goTerms')" @goPrivacy="() => $emit('goPrivacy')" :is-loading="isLoading" @onChangeAgree="(val) => $emit('onChangeAgree', val)"/>
		<div class="max-w-3xl mx-auto space-y-2 mt-6 pb-5 sm:pb-0">
			<Text v-for="text in texts" size="sm" color="gray-200" :key="text" v-html="text" class="text-center"/>
		</div>
	</Container>
</template>

<script>
import Container from '../../atoms/Containers/FullWidthBannerContainer/FullWidthBannerContainer.vue'
import Header from '../../organisms/Headers/Simple/Simple.vue'
import Text from '../../atoms/Text/Text.vue'
import RegisterForm from '../../organisms/FormLayouts/RegisterForm/RegisterForm.vue';

/**
 - Use it to show a Register template
 **/
export default {
	components: {
		Container,
		Header,
		Text,
		RegisterForm
	},
	props: {
		form: {
			type: Object,
			default: () => {
			}
		},
		texts: {
			type: Array,
			default: () => []
		},
		isLoading: {
			type: Boolean,
			default: false,
		}
	}
};
</script>
